export const IDCARD = {
    name:'身份证',
    value:/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
}

export const MOBILE = {
    name:'手机号码',
    value:/^[1][3,4,5,7,8,9][0-9]{9}$/,
}

export const INTEGER = {
    name:'正整数',
    value:/^([1-9]\d*|[0]{1,1})$/,
}

export const EMAIL = {
    name:'邮箱',
    value:/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
}

export const CARDNUMBER = {
    name:'字母和数字',
    value:/^[0-9a-zA-Z]*$/,
}

export const POSITIVENUMBER = {
    name:'正数',
    value:/^\d+(\.\d+)?$/,
}

export const POSITIVEINTEGER = {
    name:'最多保留2位小数点包含0',
    value:/^(([0-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})|([0]\.\d{1,2}|[0-9][0-9]*\.)))$/,
}

export const POSITIVEINTEGER2 = {
    name:'最多保留2位小数点不包含0',
    value:/^(([0-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})|([0]\.\d{1,2}|[0-9][0-9]*\.)))$/,
}

export const TABLENAME = {
    name:'只能包含字母数字或者下划线并且以字母开头',
    value:/^[a-zA-Z][a-zA-Z0-9_]*$/,
}

export const PASSWORD = {
    name:'必须大于6位且包含字母和数字',
    value:/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/,
}
